import type { PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Box } from '@zenobius/ui-web-components-box';
import { Page } from '@zenobius/ui-web-components-page';
import { HomeAvatar } from '@zenobius/ui-favicons';

import { GlobalNav } from '../common/GlobalNav/GlobalNav';
import * as Styles from './HomePage.css';

export function HomePage({
  children,
  currentPath,
}: PropsWithChildren<{ currentPath: string }>) {
  return (
    <Page>
      <Box
        fullscreen="true"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classnames('splash', Styles.splash)}>
          <HomeAvatar
            className={Styles.logo}
            size={128}
          />

          {children}

          <GlobalNav className={Styles.navigation} />
        </Box>
      </Box>
    </Page>
  );
}
